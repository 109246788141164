thead {
  background-color: #222a35 !important;
}

.table-title {
  color: #fff !important;
  font-size: 11px;
}

.table-global-bordered {
  border: 1px solid rgba(224, 224, 224, 1);
}

tbody > tr {
  max-height: 50px !important;
}

td > span {
  color: #4f6e91;
}

.table-text {
  color: #4f6e91;
}

.text-bolder {
  font-weight: 600;
}

.MuiTableCell-head {
  min-width: 100px !important;
  max-width: 150px !important;
  justify-content: center !important;
}

.MuiTableCell-body {
  min-width: 100px !important;
}

.custom-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: scroll;
}

.status-label {
  text-align: center;
  font-size: 0.8em;
}

.cell-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
}

.download-text {
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
}

.table-txt {
  color: #4f6e91;
}
