.name-lower {
  text-transform: lowercase !important;
}

.name-lower .name-camel {
  text-transform: capitalize;
}

.plus-icon {
  width: 0.8vw;
  min-width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
  margin-bottom: 5px;
}

.home-section-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}

.home-section {
  margin-top: 20px;
}

.home-bottom-view {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.home-section-bottom {
  align-self: flex-end;
  font-weight: bolder;
  cursor: pointer;
}

footer {
  display: flex !important;
  justify-content: center !important;
}

.footer.app {
  width: 100vw;

  transition: 0.5s;
}

.footer.app.is-open {
  width: calc(100vw - 260px);
}

.status-container {
  display: flex;
  justify-content: space-between;
}

.chevron-right {
  width: 2%;
  min-width: 10px;
  height: auto;
  object-fit: contain;
}

.footer-btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.select-view-container {
  max-width: 400px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #9aa2a8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 20px;
  padding-left: 10px;
}

.select-view-container.is-active {
  background-color: #1d9cd2;
  border: 1px solid #1d9cd2;
}

.select-view-container.is-selectable {
  cursor: pointer;
}

.select-view-content {
  display: flex;
  align-items: center;
}

.select-view-content .arrow {
  width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.select-view-title {
  color: #4a4a4a;
  font-weight: bold;
}

.select-view-title.is-active {
  color: #fff;
}

/* .select-view-container .rdt {
    border: none !important;
  }
  
  .creation-date {
    border: none;
  } */

.creation-date-input {
  border: none !important;
  text-align: end;
  margin-right: 10px;
}

.fee-input {
  max-width: 40%;
  margin-right: 5px !important;
}

.fee-input-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.files-list {
  margin-top: 10px;
  margin-bottom: 5px;
}

.file-item {
  margin-top: 25px;
  margin-bottom: 25px;
}

.rm-icon {
  width: 18px;
  height: auto;
  object-fit: contain;
}

.spinner-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-input {
  background-color: rgba(162, 170, 175, 0.12) !important;
}

.account-select {
  background: none !important;
}

.account-group {
  margin-top: 20px;
  margin-bottom: 20px;
}

.account-form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6%;
  padding-right: 16%;
}

.modal-header {
  min-height: 60px !important;
}

.doc-modal-body {
  max-height: 400px;
}

.arrow-blog {
  width: 15px;
  height: auto;
  object-fit: contain;
  margin-right: 10px;
}

.blog-title {
  text-transform: uppercase !important;
  font-weight: bold;
}

.modal-footer.logout {
  justify-content: center !important;
}

.btn-round.logout {
  margin-left: 10px !important;
  margin-right: 10px !important;
  min-width: 80px !important;
}

.reclamation-message {
  border: 1px solid #e3e3e3 !important;
  border-radius: 10px !important;
}

.reclamation-message:focus {
  border: 1px solid #f96332 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
  color: #2c2c2c;
}

.btn-lg {
  font-size: 1em !important;
  padding: 12px 48px !important;
}

.section-btn {
  margin-left: 20px !important;
}

.home-videos {
  margin-top: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-container {
  margin-top: 10%;
}

.stats-amount {
  font-size: 1.5em;
  font-weight: 600;
  color: #18ce0f;
}

.amount-view {
  height: 80px;
}

.stats-content {
  height: 400px !important;
}

.stat-counts {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #2c2c2c;
}

.count-text {
  color: #2c2c2c;
  font-weight: 500;
}

.counts-view {
  margin-top: 20px;
}

.stats-rejected {
  color: rgba(227, 1, 16, 1);
  font-weight: 500;
  margin-left: 20px;
}

.stats-success {
  color: #18ce0f;
  font-weight: 500;
  margin-left: 20px;
}
