/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.login-text-bold {
  font-weight: bold;
}

.login-text-regular {
  font-family: 400;
}

.login-logo {
  max-width: 45%;
  height: auto;
}

.home-logo {
  max-width: 50%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-content {
  margin: 40px 0;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-button {
  margin-top: 10%;
  margin-bottom: 10%;
  font-weight: normal;
  text-transform: none;
  cursor: pointer;
}

.contact-info {
  font-weight: normal;
  text-transform: none;
}

.card-footer-buttons {
  margin-top: 10%;
}

.btn-login {
  color: #037cc4 !important;
  font-weight: bolder !important;
}

.bg-input {
  background-color: rgba(225, 225, 225, 0.3) !important;
  color: white !important;
  overflow: hidden;
}

.input-icon {
  width: 20px;
  height: auto;
}

.input-group-prepend {
  border: none !important;
}

.input-group-append {
  border: none !important;
}

.login-input {
  font-weight: bold;
  font-size: medium;
}

.btn-back {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  border: 1px solid;
  border-color: #ffffff;
  background-color: rgba(144, 144, 144, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 3%;
  z-index: 4;
}

.back-icon {
  width: 25px;
  height: auto;
}

.up-icon {
  width: 30px;
  height: auto;
  margin-bottom: 10px;
}

.forgot-text-back {
  font-size: 16px;
  cursor: pointer;
}

.forgot-bottom-view {
  margin-top: 10%;
}

.rdtDay {
  color: black;
}

.rdtMonth {
  color: black;
}

.rdtYear {
  color: black;
}

.rdtNew {
  color: #999999;
}

.logo-right {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 1%;
  right: 2%;
  z-index: 4;
}

.logo-right .login-logo {
  max-width: 60%;
}

.bg-black {
  background-color: #037cc4;
}

.cgu-container {
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cgu-check {
  width: 5%;
  min-height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.check-full {
  max-width: 20px;
  height: auto;
}

.check-empty {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  border: 1px solid #36bf83;
}

.cgu-content {
  width: 95%;
}

.text-cgu-bold {
  cursor: pointer;
  font-weight: bold;
}

.register-plain {
  border-color: #fff;
  color: #fff !important;
  background-color: rgba(225, 225, 225, 0.3) !important;
  overflow: hidden !important;
}

.register-plain:focus {
  border-color: #fff !important;
  color: #fff !important;
  background-color: rgba(225, 225, 225, 0.3) !important;
}

.register-plain::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
}

.register-plain:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

.register-plain::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff !important;
}

.register-row {
  margin-bottom: 15px;
}

.login-input-error {
  font-weight: normal;
  text-transform: none;
}

.app-layout {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.app-content {
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  overflow-x: auto;
}

.app-content.is-sidebar-open {
  width: calc(100vw - 252);
  margin-left: 252px;
  transition: all 0.5s;
}

.page-description {
  padding-left: 15px;
}

.page-description h3 {
  font-weight: bold;
  margin-bottom: 0px !important;
}

.top-btn-view {
  display: flex;
  justify-content: space-between;
}

.app-main {
  min-height: 80vh;
  background-color: #fff;
}

.display-row {
  display: flex;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between !important;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-tanslate {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end !important;
  }
}

ol.breadcrumb {
  padding: 0rem 1rem !important;
  align-self: center !important;
  margin-top: 7%;
  background-color: transparent !important;
}

li.breadcrumb-item a {
  color: #ff7a00 !important;
}

.btn-round {
  max-height: 42px;
}

.filters-container {
  display: flex;
  flex-direction: row;
}

.filter-icon {
  width: 2vw;
  min-width: 27px;
  height: auto;
  object-fit: contain;
}

.stats-filter-icon {
  width: 2vw;
  min-width: 27px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  bottom: 5px;
}

.rdt .form-control.filter {
  height: 37px !important;
}

.text-align-center {
  display: flex;
  align-self: center;
  margin-top: 10px;
}

.justify-end {
  justify-content: flex-end;
}

.text-align-center .btn-round {
  margin-top: 0px !important;
}

.react-select.filter {
  margin-bottom: 0 !important;
  width: 100% !important;
}

.page-icon {
  width: 2vw;
  min-width: 27px;
  height: auto;
  object-fit: contain;
  margin-top: 2%;
  margin-right: 10px;
}

.page-details-icon {
  width: 2vw;
  min-width: 27px;
  height: auto;
  object-fit: contain;
}

.page-description-content {
  display: flex;
  align-items: flex-start;
}

.page-title-text {
  color: #004282;
  font-weight: bold;
  font-size: 1.6em !important;
}

.topbar-right {
  display: flex;
  width: 40vw;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.topbar-text {
  color: #222a35 !important;
  text-align: right;
}

.topbar-disconnect {
  cursor: pointer;
}

.breadcrumbs-container {
  display: flex;
  align-items: center;
}

.status-employer {
  cursor: pointer;
}

.password-input {
  cursor: pointer;
}

.input-addon {
  width: 20px;
  height: auto;
  object-fit: contain;
  /* right: 10px;
  bottom: 15px; */
}
