.button-tag {
  height: 50px;
  border: 1px solid #9aa2a8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4a4a4a;
  font-size: 0.9em;
  text-align: center;
  cursor: pointer;
  margin: 10px 10px;
}

.button-tag.sm {
  min-width: 150px;
  max-width: 180px;
}

.button-tag.lg {
  min-width: 180px;
  max-width: 210;
}

.button-tag.xs {
  min-width: 80px;
  max-width: 110;
}

.button-tag.active {
  background-color: #009ee4;
  border: 1px solid #009ee4;
}

.button-tag.primary {
  background-color: #004282;
  border: 1px solid #004282;
}

.button-tag-label.active {
  color: #fff;
}
