/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  width: 250px;
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-size: 250px 100%;
  background-color: #f1f3f6;
  position: fixed;
  color: #4a4a4a;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 1px;
  transition: 0.5s;
}

.sidebar-footer {
  height: 40vh !important;
  cursor: pointer;
  position: relative;
  bottom: 80px;
}

.sidebar-header h3 {
  color: #4a4a4a;
  padding: 1em;
}

.sidebar ul p {
  color: #4a4a4a;
  padding: 10px;
}

.sidebar a {
  color: #4a4a4a;
}

.sidebar a:hover {
  color: #009ee4;
  background-color: transparent;
  font-weight: bolder;
}

.sidebar a:focus {
  color: #009ee4 !important;
  background-color: transparent;
  font-weight: bolder;
}

.side-item-active {
  font-weight: bolder;
  color: #009ee4 !important;
}

.sidebar hr {
  background: #fff;
  width: 100%;
}

.sidebar .nav-item:hover {
  background-color: transparent;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  color: #7386d5;
  background: #fff;
}

.items-menu {
  color: #4a4a4a;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.side-menu {
  height: 85vh;
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
}

@media only screen and (max-width: 800px) {
  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }

  .app-content.is-sidebar-open {
    display: none;
  }
}

.text-disconnect {
  font-size: 16px;
  margin: 0.5rem 1rem;
}
