@media screen and (max-width: 500px) {
  .creation-content {
    padding: 0 !important;
  }
}

.creation-footer {
  margin-left: 2%;
  margin-right: 2%;
}

.creation-content {
  width: 100%;
  min-height: 45vh;
  padding: 0 10%;
}

.creation-content.benef-list {
  max-height: 60vh;
  overflow-y: scroll;
}

.stepper-view {
  min-height: 60px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
}

.creation-element {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.creation-element h5 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 5px !important;
}

.convs-types {
  display: flex;
  width: 40%;
}

.pack-view {
  height: 90px;
  width: 70%;
  display: flex;
  align-items: center;
}

.pack-left {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pack-remove {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.photo-preview {
  width: 80px;
  display: flex;
}

.pack-photo-remove {
  width: 10px;
  height: auto;
  object-fit: contain;
  position: relative;
  bottom: 20px;
}

.tabs-view-container {
  display: flex;
  overflow-x: scroll;
  height: 50px;
}

.tab-view {
  min-width: 80px;
  height: 50px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tab-view.active {
  font-weight: bold;
  color: #4a4a4a;
}

.space-left {
  margin-left: 20px;
}

.space-right {
  margin-right: 20px;
}

.conv-tab-content {
  width: 100%;
  min-height: 30vh;
}

.ok-img {
  width: 30%;
  height: auto;
  object-fit: contain;
}

.step-chk-btn {
  cursor: pointer;
  margin: 10px 0;
}

.step-checkmark {
  margin-right: 10px;
  width: 18px;
  height: auto;
  object-fit: contain;
}
