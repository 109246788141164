.demande-title-text {
  color: #444b54;
  font-weight: bold;
  font-size: 1.8em !important;
}

.requests-list {
  padding: 20px 30px;
}

.request-table {
  display: flex;
  align-items: center;
}

.detail-title-text {
  color: #004083;
  font-weight: bold;
  font-size: 1.3em !important;
}

.req-info-view {
  display: flex;
  min-height: 30px;
  align-items: center;
}

.req-info-view h5 {
  font-weight: bold;
  font-size: 0.9em !important;
}

.req-info-value {
  margin-left: 5px;
  color: #004083;
  font-weight: bold;
  font-size: 0.9em !important;
  margin-bottom: 15px !important;
}

.row-info {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.download-l {
  cursor: pointer;
}
