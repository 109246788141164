.popover {
  .arrow:before {
    border-color: transparent !important;
  }
}

.popover-primary {
  .popover {
    @include popover-color($primary-color, $white-color);
    .arrow:after {
      border-left-color: $primary-color;
    }
  }
}

.popover-secondary {
  .popover {
    @include popover-color($secondary-color, $white-color);
    .arrow:after {
      border-left-color: $secondary-color;
    }
  }
}

.popover-info {
  .popover {
    @include popover-color($info-color, $white-color);
    .arrow:after {
      border-left-color: $info-color;
    }
  }
}

.popover-warning {
  .popover {
    @include popover-color($warning-color, $white-color);
    .arrow:after {
      border-left-color: $warning-color;
    }
  }
}

.popover-danger {
  .popover {
    @include popover-color($danger-color, $white-color);
    .arrow:after {
      border-left-color: $danger-color;
    }
  }
}

.popover-success {
  .popover {
    @include popover-color($success-color, $white-color);
    .arrow:after {
      border-left-color: $success-color;
    }
  }
}
