.app-dropdown {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 50px;
  border: 1px solid #9aa2a8;
  border-radius: 8px;
  background-color: #ffffff !important;
  color: #4a4a4a !important;
  font-weight: bold;
  box-shadow: none;
  margin: 0 !important;
}

.dropdown-arrow {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.dropdown-options {
  max-height: 200px !important;
  overflow-y: scroll;
}

.app-dropdown.sm {
  width: 240px;
}

.app-dropdown.lg {
  width: 280px;
}

.app-dropdown.xs {
  width: 140px;
}
