.empty-list-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20vh;
}

.empty-list-view h4 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  font-size: 1em;
  color: #5a5a5b;
}

.empty-icon {
  width: 10%;
  height: auto;
  object-fit: contain;
}

.list-top-view {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.text-bold {
  font-size: 1.1em;
  font-weight: 700;
  font-family: "Avenir-Bold";
}

.export-btn {
  cursor: pointer;
}

.filters-view {
  min-height: 60px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.filter-input {
  max-width: 200px !important;
  height: 35px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.filter-input:focus {
  border: 1px solid #e3e3e3;
}

.react-select__value-container {
  width: 200px !important;
  height: 35px;
}
.react-select__control--is-focused,
.react-select__control--is-focused:hover {
  border: 1px solid #e3e3e3 !important;
}

.btn-round.filter {
  height: 35px !important;
  display: flex;
  align-items: center;
}

.societe-list {
  justify-content: space-between;
}

.societe-logo {
  width: 50px;
  min-width: 26px;
  height: auto;
  object-fit: contain;
}
