.app-input {
  height: 50px;
  border: 1px solid #9aa2a8;
  border-radius: 8px;
}

.app-input.lg {
  max-width: 504px;
  width: 100%;
}

.app-input.sm {
  max-width: 168px;
  width: 100%;
}

.app-input.md {
  max-width: 360px;
  width: 100%;
}
