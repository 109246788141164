.card-login {
  max-width: 380px !important;
  min-width: 300px !important;
}

.auth-logo-container {
  max-width: 380px !important;
  min-width: 300px !important;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.login-form {
  padding-left: 10% !important;
  padding-right: 10% !important;
  padding-top: 5% !important;
}

.login-form .form-title {
  margin-bottom: 0px !important;
  margin-left: 10px !important;
}

.login-view-container {
  width: 100%;
  height: 100%;
  align-items: center;
}
