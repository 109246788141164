.garantie-view {
  margin-bottom: 10px;
  margin-top: 10px;
}

.section-header {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: #f1f3f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.section-header-content {
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.section-side {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkmark-view {
  cursor: pointer;
}

.checkmark {
  width: 26px;
  height: auto;
  object-fit: contain;
}

.arrow-expansion {
  width: 14px;
  height: auto;
  object-fit: contain;
}

.section-content-view {
  width: 100%;
  min-height: 200px;
  margin-bottom: 30px;
  position: relative;
}

.readonly-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.section-content-view.readonly {
  opacity: 0.5;
}

.section-deps-view {
  display: flex;
}

.deps-element {
  margin-right: 20px;
}

.creation-element.deps-element h5 {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 5px !important;
}

.formule-view {
  margin-top: 10px;
  min-height: 100px;
  width: 100%;
}

.formule-container {
  display: flex;
}

.constraints-view {
  min-height: 100px;
  width: 100%;
}

.dependencies-view {
  min-height: 100px;
  width: 100%;
}

.formule-title {
  color: #009ee4;
  font-weight: 600;
  font-size: small;
}

.formule-view-item {
  display: flex;
  width: 100%;
}

.formule-rm {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.deps-rm {
  width: 40px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tarifs-view {
  display: flex;
}

.rm-icon {
  width: 15px;
  height: auto;
  object-fit: contain;
}

.section-constraint-view {
  display: flex;
}

.button-tag-grp {
  display: flex;
  margin-bottom: 20px;
}
